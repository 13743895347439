<script>
  import lodash from 'lodash';
  import controller from '@/controller';
  import dao from '@/dao';

  export default {
    name: 'feed-and-mentions-mixin',
    data() {
      return {
        watchedUsers: [],
        searchSettings: [],
        isSavingFeedSettings: false,
        isFeedSettingsUpdated: false,
      };
    },
    watch: {
      tweets(newTweets, oldTweets) {
        if (!this.$refs['feed-item-box']) return;
        const diff = lodash.difference(oldTweets, newTweets);
        if (diff[0] === null) return;

        if (Array.isArray(diff) && diff.length > 0) {
          const idOfRemovedTweet = diff[0].id;
          if (idOfRemovedTweet) {
            const indexOfProcessedMention = oldTweets
              .map((tweet) => tweet.id)
              .indexOf(idOfRemovedTweet);

            this.$nextTick(() => {
              const feedItemBox = this.$refs['feed-item-box'];
              const feedItemBoxIndex = feedItemBox[indexOfProcessedMention];
              feedItemBoxIndex && feedItemBoxIndex.focus();
            });
          }
        }
      },
      searchFeedTweets(newTweets, oldTweets) {
        if (!this.$refs['feed-item-box']) return;
        const diff = lodash.difference(oldTweets, newTweets);
        if (diff[0] === null) return;

        if (Array.isArray(diff) && diff.length > 0) {
          const idOfRemovedTweet = diff[0].id;
          if (idOfRemovedTweet) {
            const indexOfProcessedTweet = oldTweets
              .map((tweet) => tweet.id)
              .indexOf(idOfRemovedTweet);

            this.$nextTick(() => {
              const index =
                this.tweetsToShow.length > 1
                  ? indexOfProcessedTweet + this.tweets.length
                  : indexOfProcessedTweet;
              const feedItemBox = this.$refs['feed-item-box'];
              const feedItemBoxIndex = feedItemBox[index];
              feedItemBoxIndex && feedItemBoxIndex.focus();
            });
          }
        }
      },
    },
    computed: {
      watchedSearchTerms() {
        return this.searchSettings
          .filter((settings) => settings.type === 'simple')
          .map((settings) => settings.exactPhrase);
      },
      advancedSearchSettings() {
        return this.searchSettings.filter((settings) => settings.type === 'advanced');
      },
    },
    methods: {
      async updateFeedSettings() {
        this.isSavingFeedSettings = true;
        const noEmptySlotsInWatchedUsers = this.watchedUsers.filter((n) => n);

        if (
          noEmptySlotsInWatchedUsers.some((username) =>
            username.trim().includes('https://twitter.com')
          )
        ) {
          this.swalModal({
            title: 'Error',
            html: `Write only the username of the users you want to watch. <br /> Don't add links to their Twitter profile. <br />
            <ul>
              <li> <strong>https://twitter.com/username</strong> ❌ </li>
              <li> <strong>username</strong> ✅ </li>
              <li> <strong>@username</strong> ✅ </li>
            </ul>
            `,
            type: 'warning',
          });

          this.isSavingFeedSettings = false;
          return;
        }
        const savedWatchedUsers = lodash.get(this.userProfile, 'feedSettings.watchedUsers', []);
        const savedSearchSettings = lodash.get(this.userProfile, 'feedSettings.searchSettings', []);

        const updateMap = {};

        const didWatchedUsersChange =
          this.watchedUsers.length !== savedWatchedUsers.length ||
          this.watchedUsers.some(
            (username) => !savedWatchedUsers.some((user) => user.username === username)
          );

        if (didWatchedUsersChange) {
          this.watchedUsers = noEmptySlotsInWatchedUsers.map((username) => {
            if (username.startsWith('@')) {
              const [, cleanUsername] = username.split('@');
              return cleanUsername.trim();
            } else {
              return username.trim();
            }
          });
          try {
            let twitterIdsAndHandles = [];

            if (this.watchedUsers.length > 0) {
              twitterIdsAndHandles = await controller.twitter.getTwitterUsersByUsernames(
                this.currentUser,
                this.userProfile.uid,
                this.watchedUsers
              );
            }

            updateMap['feedSettings.watchedUsers'] = twitterIdsAndHandles;
          } catch (error) {
            this.isSavingFeedSettings = false;
            let message = 'An error occurred while saving your settings';

            if (error.response.status === 404) {
              let usersNotFound = '';
              error.response.data.usersNotFound.forEach((user) => {
                usersNotFound += `<li>${user}</li>`;
              });
              message = `Could not find the following users:<br><ul>${usersNotFound}</ul>`;
            }

            const invalidUsers = [];
            if (error.response.status === 401) {
              let protectedUsersNotBeingFollowed = '';
              error.response.data.protectedUsers.forEach((user) => {
                protectedUsersNotBeingFollowed += `<li>${user}</li>`;
                invalidUsers.push(user);
              });
              message = `These users have protected tweets and you are not following them:<br><ul>${protectedUsersNotBeingFollowed}</ul>`;
            }

            if (invalidUsers.length > 0) {
              // Remove the invalid users, because in the New UI we have auto-save, which will trigger an error message loop
              this.watchedUsers = this.watchedUsers.filter((user) => !invalidUsers.includes(user));
            }

            this.swalModal({
              title: 'Error',
              html: message,
              type: 'warning',
            });
            throw error;
          }
        }

        const didSearchSettingsChange =
          this.searchSettings.length !== savedSearchSettings.length ||
          !lodash.isEqual(this.searchSettings, savedSearchSettings);
        if (didSearchSettingsChange) {
          updateMap['feedSettings.searchSettings'] = this.searchSettings;
        }

        if (!lodash.isEmpty(updateMap)) {
          this.isFeedSettingsUpdated = true;
          updateMap['feedSettings.isFeedSettingsUpdatedRecently'] = true;

          await dao.userProfile.updateFeedSettings(this.userProfile.uid, updateMap);

          if (didWatchedUsersChange) {
            const watchedUsernamesSet = new Set(
              this.watchedUsers.map((username) => username.toLowerCase())
            );

            this.watchedUsers = updateMap['feedSettings.watchedUsers']
              .filter((user) => watchedUsernamesSet.has(user.username.toLowerCase()))
              .map((user) => user.username);
          }
        }
        this.isSavingFeedSettings = false;
      },
    },
  };
</script>

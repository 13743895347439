<template>
  <div class="flex gap-6">
    <base-button
      v-for="emoji in quickReplyEmojis" :key="emoji" 
      :disabled="disabled"
      type="primary"
      size="sm"
      tabindex="-1"
      @click="$emit('reply-and-like', emoji)"
      class="outline-none focus:outline-none"
    >
      {{ emoji }}
    </base-button>
  </div>
</template>
<script>
  export default {
    name: 'quick-reply-emojis',
    data() {
      return {
        quickReplyEmojis: ['🎉', '🥂', '👍', '😂', '👑'],
      };
    },
    props: {
      disabled: {
        type: Boolean,
        default: false,
        required: false
      }
    },
  };
</script>

import image1 from './hypefuryTrialTweetsImages/image1.png';
import image2 from './hypefuryTrialTweetsImages/image2.png';
import image3 from './hypefuryTrialTweetsImages/image3.png';
import image4 from './hypefuryTrialTweetsImages/image4.png';
import image5 from './hypefuryTrialTweetsImages/image5.png';
import image6 from './hypefuryTrialTweetsImages/image6.png';
import image7 from './hypefuryTrialTweetsImages/image7.png';
import image8 from './hypefuryTrialTweetsImages/image8.png';
import image9 from './hypefuryTrialTweetsImages/image9.png';
import image10 from './hypefuryTrialTweetsImages/image10.png';
import image11 from './hypefuryTrialTweetsImages/image11.png';

export default [
  {
    status: `Trying out @hypefury for the first time!

Let's see what they have in store 👀`,
  },
  {
    status: `You know what's better than shitposting?

Scheduling that shitpost. This is my first from @hypefury 😌`,
  },
  { status: `I wanna create more than I consume. @hypefury's gonna help me do that from now!` },
  {
    status: `I'm trying @hypefury for the first timeeee.

This is a code-speaking... meep morp zeeep.

Just kidding, it's still me (or is it?)`,
  },
  {
    status: `Just started using @hypefury

Let's see how this goes ✌️`,
  },
  {
    status: `Y'all gonna get more of this content cause I just started scheduling it :P  cc - @hypefury`,
  },
  {
    status: `Been seeing this Hypefury pop up a lot on my feed.. 👀

Thought I'd give it a try.

@hypefury`,
  },
  {
    status: `Am I a part of  Money Twitter..if I've finally tried @hypefury? :p`,
  },
  {
    status: `First tweet from @hypefury. Mic check 1 2..`,
  },
  {
    status: `Gonna engage more with y'all cause @hypefury will take care of my Tweeting times and shit.`,
  },
  {
    status: `Using @hypefury to double down on great content.`,
  },
  {
    status: `Good Morning to everyone except people who are against scheduling on Twitter.

In other news, I started trying @hypefury`,
  },
  {
    status: `You ain't working if you ain't working till 1AM.

Kidding.

I scheduled this first tweet from @hypefury yesterday.

I'm still sleeping rn. AUTOMATION RULES!`,
  },
  {
    status: `You don't need a reason to choose automation. You just need to have a life outside Twitter.

In other news, I'm trying @hypefury.`,
  },
  {
    status: `I'm finally choosing scheduling cause y'all deserve better than a tweet dump of 40 tweets in 4 hours.

@hypefury - Do your magic!`,
  },
  {
    status: `Can finally write - "@hypefury auto-plug tweets making me money when I sleep" or is it too soon?`,
  },
  {
    status: `I laid my hands on Twitter cheat code.

It's called @hypefury 👀`,
  },
  {
    status: `I'm becoming a TWITTER MACHINE. Of course, it was about time to try @hypefury`,
  },
  {
    status: `I'm gonna do magic here.

I'm still asleep but this tweet is getting posted.

cc - @hypefury`,
  },
  {
    status: `Finally doing it ft. @hypefury`,
    image: image1,
  },
  {
    status: `Major afk vibes. ft. @hypefury`,
    image: image2,
  },
  {
    status: `.@hypefury do your magicxx pls`,
    image: image3,
  },
  {
    status: `YES I do! Trying it today @hypefury`,
    image: image4,
  },
  {
    status: `Letsss go baby!! @hypefury`,
    image: image5,
  },
  {
    status: `My wife wanted me to leave Twitter and go for dinner.

I couldn't leave Twitter.

So, @hypefury is doing it for me now :)`,
  },
  {
    status: `I love being here and tweeting

But I got a life

Thanks @hypefury!`,
  },
  {
    status: `I came here after the Hypefury v Other tool drama 👀
Let's see what they've got!

cc - @hypefury`,
  },
  {
    status: `I started using @hypefury a few hours back and I've already scheduled tweets for the next week.

Hypefury is awesome!`,
  },
  {
    status: `Started using @hypefury today.

Loads of inspiration and tweets already!`,
  },
  {
    status: `I'm trying out @hypefury

Because I ..

- am not an AI
- want to tweet real-life shit
- don't want to spend 23 hours on Twitter`,
  },
  {
    status: `They said I could make money from Twitter 👀👀👀
Lemme see how that works!

In other news, trying @hypefury`,
  },
  {
    status: `It's gonna be a tough choice to choose between

Scheduling memes

Or

Scheduling "websites that feel illegal to know"

What should be my first tweet with @hypefury?`,
  },
  { status: `In other news, I’m trying @hypefury`, image: image6 },
  { status: `Gonna be a tough choice now that I’m using @hypefury`, image: image7 },
  {
    status: `Just wanted to remind myself that I have a life outside Twitter.

So trying @hypefury`,
    image: image8,
  },
  {
    status: `Started using @hypefury

I’ll blame them if I spend 19 hrs here now`,
    image: image9,
  },
  { status: `Thanks @hypefury!`, image: image10 },
  {
    status: `It do be like that sometimes

cc - @hypefury`,
    image: image11,
  },
];

import { isEnvProd } from '@/config';

export function initHowuku() {
  if (!isEnvProd) return;

  loadHowuku();
}

function loadHowuku() {
  (function (t, r, a, c, k) {
    (c = ['track', 'identify', 'converted']),
      (t.o = t._init || {}),
      c.map(function (n) {
        return (t.o[n] =
          t.o[n] ||
          function () {
            (t.o[n].q = t.o[n].q || []).push(arguments);
          });
      }),
      (t._init = t.o),
      (k = r.createElement('script')),
      (k.type = 'text/javascript'),
      (k.async = true),
      (k.src = 'https://cdn.howuku.com/js/track.js'),
      k.setAttribute('key', a),
      r.getElementsByTagName('head')[0].appendChild(k);
  })(window, document, 'nvmGW7eJdkpPrYw81xXQKA');
}

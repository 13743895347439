<template>
  <div
    class="rounded-2xl p-6 border-2 cursor-pointer"
    :class="
      tweetsToQueue.includes(source)
        ? 'bg-main-color-50 border-main-color-100 dark-mode:bg-dark-mode-70'
        : 'bg-white border-gray-90 dark-mode:bg-dark-mode-70 dark-mode:border-dark-mode-70'
    "
    @click.prevent="$parent.$parent.$emit('update-tweets', source)"
  >
    <div class="grid gap-6">
      <div class="flex items-center gap-2">
        <div class="w-8 h-8">
          <img
            :src="source.user ? source.user.profilePhoto : userProfile.photoURL"
            :alt="`${source.user ? source.user.username : userProfile.username}'s profile picture`"
            class="rounded-full w-full h-full object-cover"
          />
        </div>
        <div class="flex items-center gap-2 flex-1">
          <span class="font-bold text-lg md:text-xl dark-mode:text-white">{{
          source.user ? source.user.name : userProfile.name
          }}</span>
          <span class="text-lg text-gray-80 dark-mode:text-dark-mode-10 block w-16 md:w-auto truncate"
            >@{{ source.user ? source.user.username : userProfile.username }}</span
          >
        </div>
      </div>
      <p
        class="text-lg dark-mode:text-white whitespace-pre-wrap break-words max-w-sm"
        v-html="formatTweet(source.text)"
      />
      <div class="flex items-center gap-2">
        <span class="text-lg text-gray-100 dark-mode:text-dark-mode-10">
          {{ getFormattedTime(source.time) }}
        </span>
      </div>
    </div>
  </div>
</template>
<script>
  import { mapGetters, mapState } from 'vuex';
  import { formatTweet } from '@/util/formatTweet';

  export default {
    computed: {
      ...mapGetters({ currentUser: 'getCurrentUser', userProfile: 'getUserProfile' }),
    },
    methods: {
      formatTweet,
    },
    props: {
      source: {
        type: Object,
      },
      tweetsToQueue: {
        type: Array,
        default: () => [],
      },
      getFormattedTime: {
        type: Function,
        default: () => ({}),
      },
    },
  };
</script>

<template>
  <div class="h-screen relative onboarding-font">
    <div v-if="!analyzing" class="flex items-start justify-between h-full">
      <div :class="leftBg" class="flex-1 h-full px-6 xl:px-0 hidden md:block">
        <slot name="left-content" />
      </div>
      <div :class="rightBg" class="flex-1 h-full relative px-6 xl:px-0">
        <slot name="right-content" />
      </div>
    </div>
    <div v-else class="flex items-center justify-center h-full bg-white dark-mode:bg-dark-mode-80">
      <slot name="full-content" />
    </div>
  </div>
</template>

<script>
  import { checkTheme } from '@/util/checkTheme';
  import MobileMixin from '@/views/Mixins/MobileMixin';
  export default {
    name: 'onboarding-layout',
    components: {},
    mixins: [MobileMixin],
    props: {
      leftBg: {
        type: String,
        default: 'bg-white',
      },
      rightBg: {
        type: String,
        default: 'bg-white',
      },
      analyzing: {
        type: Boolean,
        default: false,
      },
      showLogo: {
        type: Boolean,
        default: false,
      },
      showSteps: {
        type: Boolean,
        default: true,
      },
    },
    mounted() {
      const isDark = window.matchMedia('(prefers-color-scheme: dark)').matches;
      checkTheme(isDark);
    },
  };
</script>
